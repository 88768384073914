module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-netlify-cms/gatsby-browser'),
      options: {"plugins":[],"enableIdentityWidget":true},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-web-font-loader/gatsby-browser'),
      options: {"plugins":[],"custom":{"families":["Blackout Sunrise","Ostrich Sans Inline","Ostrich Sans Rounded","Ostrich Sans Heavy"],"urls":["/fonts/font.css"]}},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    }]
